
@import '../config/variables';
//Objects
@import '../objects/buttons';
@import '../objects/cards';
.home{
    &__hello{
       
        position: relative;
        width: 100%;
        max-height: 750px;
        height: 750px;
        h1{
            color: webColor(secondaryColor);
            font-size: 68px;
            font-style: normal;
            font-weight: 700;
            line-height: 64px;
            strong{
                color: webColor(mainColor);
            }
        }
        h5{
            padding-top: 30px;
           
        }
        p{
            padding-top: 15px;
            padding-bottom: 20px;
        }
        .text_content{
            max-width: 570px;
            padding-top: 210px;
        }
        .img_content{
            position: absolute;
            
            display: flex;
            align-items: baseline;
            justify-content: flex-end;
            bottom: 0;
            right: 0;

            &::before{
                position: absolute;
                content: '';
                background-image: url(../../imgs/bg_home.svg);
                background-repeat: no-repeat;
                background-position: right;
                background-size: 750px;
                width: 1000px;
                height: 850px;
                bottom: 0;
                z-index: -1;
            }

            img{
                width: 514px;
                height: 686px;
            }

        }
        @include respond(tablet){
                padding: 0 20px;
        }
        @include respond(mobile){
            max-height:none;
            height: auto;
            
            .text_content{
                width: 100%;
                padding-top: 90px;
                h1{
                    max-width: 280px;
                     font-size: 32px;
                   
                    line-height: 38px;
                }
                h5{
                    max-width: 320px;
                }
                p{
                    max-width: 320px;
                }
            }
            .img_content{
                position: relative;
                justify-content: flex-start;
                bottom: 0;
                right: 0;
                width: 100%;
                &::before{
                    width: 100%;
                    height: 110%;
                        background-size: contain;
                }
                img{
                    width: 100%;
                    height: auto;
                    position: relative;
                     right: -20px;
                }
            }
        }
    }
    &__howorks{
        padding: 100px 0;
        background-color: webColor(bgColor);
        .c-card{
            height: 285px;
           
            .top{
                height: 45px;
                span{
                    font-weight: 500;
                }
            }
        }
        @include respond(bigtablet){
            ul{
                flex-direction: column !important;
                justify-content: flex-start !important;
                align-items: center!important;
            }
            li{
                margin-top: 50px;
            }
            .c-icon{
                width: 40px;
                height: 40px;
                &.regular{
                    width: 55px;
                }
            }
        }

    }
    &__userdata{
        padding: 100px 0;
        background-color: webColor(terciaryColor);
        strong{
            color: webColor(mainColor);
            font-size:fontSize(big);
            font-weight: 700;
            line-height: normal;
        }
        @include respond(bigtablet){
            padding: 60px 0;
            ul{
                flex-direction: column !important;
                justify-content: flex-start !important;
                align-items: center!important;
            }
            li{
                margin-bottom: 50px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    &__faq{
        padding: 100px 0;
        background-color: webColor(bgColor);
        ul{
            padding: 0;
        }
        li{
            border-bottom: 1px solid webColor(terciaryColor);
            button{
                font-size: 24px;
                padding: 0;
                width: 70px;
                min-width: auto;
               
            } 
        }
        .faq__expander{
            p{
                padding-top: 40px;
                padding-bottom: 20px;
                font-weight: 400;
                line-height: 25px;
            }
        }
        @include respond(bigtablet){
            ul{
               
                width: 85%;
                margin: 0 auto;
            }
            li{
                margin: 0 2.5%!important;
                width: 100%;
            }
            .wrapper{
                width: 85%;
                margin: 0 auto;
                align-items: flex-start!important;
            }
            h5{
                margin-bottom: 25px;
            }
        }
        
    }
}