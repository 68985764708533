
@import '../base/basic';
@import '../config/variables';

.c-card{
    max-width: 364px;
    min-width: 290px;
    border-radius: 20px;
    background: var(--Blanco, #FFF);
    box-shadow: 0px 4px 18px 0px rgba(93, 76, 198, 0.10);
    display: flex;
    padding: 32px 40px;
    flex-direction: column;
    align-items: flex-start;
    span{
        &.numeral{
            font-size: fontSize(little);
            font-weight: 500;
            line-height: 33px;
        }
    }

    &__survey{
        overflow: hidden;
        max-width: 300px;
        min-width: 290px;
        height: 386px;
        padding: 0;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-radius: 20px;
        .top{
            width: 100%;
            height: 180px;
            overflow: hidden;
            position: relative;
            .img_label{
                position: absolute;
                top: 20px;
                left: 20px;
                min-width: 70px;
                padding: 0 10px;
                height: 30px;
                border-radius: 4px;
                background-color: webColor(link);
                display: flex;
                align-items: center;
                justify-content: center;
                span{
                    color: webColor(txtSecondaryColor);
                }
                &.complete{
                    background-color: webColor(linkColor);
                }
                &.closed{
                    background-color: webColor(warning);
                }
                
            }
            img{
                width: 100%;
               
            }
            span{
                 font-weight: 500;
            }
        }
        .main{
            padding: 0 20px;
            span{
                font-size: fontSize(little);
            }
        }
        [data-points]{
            color: webColor(mainColor);
            font-weight: 600;
        }
        [data-time]{
            color: webColor(link);
             font-weight: 500;
        }
        [data-title]{
            
        }
        [data-url]{
            
        }
    }

    @include respond(tablet){
        &__survey{
            min-width: auto;
            height: 300px;
            .top{
               height: 127px;
            }
        }
    }
    
}
.c-icon{
    width:32px;
    height: 32px;
    &.regular{
        width: 40px;
        height: 40px;
    }
}

.c-login{
    span{
        font-weight: 600;
         a{
            cursor: pointer;
            color: webColor(mainColor);
            text-decoration: none;
            }
    }
}